import React from 'react';
import {Row, Col, Button} from 'antd';

import Layout from '../components/layout';
import {WEB_APP_URL} from '../constant';
// import './style.less'
import FooterBox from '../components/footerBox';

const LegalNoticesPage = () => {
    return (
        <Layout title="Legal Notices">
            <div className="max-width-container">
                <Row>
                    <Col md={{span: 16, offset: 4}}>
                        <div className="DisplayBlackCenter mb16 Title">Legal Notices</div>
                        <div className="LeadingBlackCenter mb56">All TicketTamer intellectual property rights will be enforced vigorously.</div>
                        <div className="H2BlackLeft mb24">Patent Pending</div>
                        <div className="BodyBlackLeft Description mb40">ReubenLaw LLCl, dba TicketTamer, has a patent pending on the TicketTamer client management system, dating back to October 8, 2019. Copying, replicating, reverse engeeering, and other encroachments on its pending patent are strictly prohibited. For questions, contact Armstrong Teasdale LLP, 7700 Forsythe Blvd., St. Louis, MO 63105.</div>

                        <div className="H2BlackLeft mb24">Copyright</div>
                        <div className="BodyBlackLeft Description mb40">The design, text, graphics and selection and arrangement thereof, and services, legal guidance, and all other content found on our website are copyrighted by ReubenLaw LLC. Copyright © ReubenLaw LLC 2018. All rights reserved. You may not copy, imitate or use them, in whole or in part, without our prior written consent. In addition, the look and feel of TicketTamer is copyrighted, and you may not copy, imitate or use it, in whole or in part, without our prior written consent. For information, contact ReubenLaw LLC, P.O. Box 39023, St. Louis, Mo., 63109.</div>

                        <div className="H2BlackLeft mb24">Trademark</div>
                        <div className="BodyBlackLeft Description mb40">TicketTamer® is a registered trademark in the U.S. Patent and Trademark Office. These and any other TicketTamer product or service names or slogans displayed on TicketTamer products are trademarks of ReubenLaw LLC. You may not copy, imitate or use them, in whole or in part, without our prior written consent. In addition, the look and feel of TicketTamer is the service mark, trademark and/or trade dress of TicketTamer and you may not copy, imitate or use it, in whole or in part, without our prior written consent. For information, contact ReubenLaw LLC, P.O. Box 39023, St. Louis, Mo., 63109.</div>

                        <div className="H2BlackLeft mb24">Goodwill</div>
                        <div className="BodyBlackLeft Description mb40 PreText">{`All goodwill generated from the use of TicketTamer’s intellectual property is reserved for the use of TicketTamer, exclusively.

TicketTamer retains all right, title and interest in and to its products and services, including, without limitation, software, images, text, graphics, illustrations, logos, service marks, copyrights, photographs, videos, music, and all related intellectual property rights. Except as otherwise provided in this agreement, you may not, and may not permit others to: (i) reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer or create derivative works of any of our products and services; (ii) sell, license, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any of our products and services; or (iii) circumvent or disable any security or technological features of our products and services.

All TicketTamer intellectual property rights will be vigorously enforced.`}</div>
                    </Col>
                </Row>
            </div>
            <FooterBox />
        </Layout>
    );
}

export default LegalNoticesPage;
